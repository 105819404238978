/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
// rct theme provider
import DashboardPanel from "../routes/dashboard/widgets/Dashboard";
import ClientsWidget from "../routes/dashboard/widgets/Clients";
import ActivitiesWidget from "../routes/dashboard/widgets/Activities";
import ResourcesWidget from "../routes/dashboard/widgets/Resources";
import CoursesWidget from "../routes/dashboard/widgets/Courses";
import RewardsWidget from "../routes/dashboard/widgets/Rewards";
import CourseView from "../routes/dashboard/widgets/CourseView"
import ClientAlerts from "../routes/dashboard/widgets/ClientAlerts";
import ClientDashboard from "../routes/dashboard/widgets/ClientDashboard"
import Alerts from "../routes/dashboard/widgets/Alerts"

import { getFirebaseCustomToken } from '../actions';
import { signInFirebaseWithAuth0Token } from '../helpers/helpers';
import LifeSherpaLoading from '../components/LifeSherpaLoading';
import { Route, Switch } from 'react-router-dom';
import UIFallbackScreen from "../components/UIFallbackScreen";
import Touchpoint from "../routes/dashboard/widgets/Touchpoint"


const widgetsComponent = {
    //'alerts': Alerts,
    'client-alerts': Alerts,
    'dashboard-summary': DashboardPanel,
    'clients': ClientsWidget,
    'activities': ActivitiesWidget,
    'resources': ResourcesWidget,
    'courses': CoursesWidget,
    'rewards': RewardsWidget,
    //'client-alerts': ClientAlerts,
    'client-dashboard': ClientDashboard,
    'course-view': CourseView,
    'touchpoint': Touchpoint
}

class WidgetController extends Component {
    state = {
        loading: false
    }
    async componentDidMount() {
        this.setState({ loading: true });
        const auth0Token = localStorage.getItem("auth0Token");
        if (auth0Token) {
            console.log("Signing with firebase with auth0Token : ", auth0Token)
            try {
                const token = await this.signInFirebase(auth0Token);
                console.log("Firebase idToken : ", token);
                this.setState({ loading: false });
            } catch (e) {
                console.error("Error occured while signing with firebase : ", e);
                this.setState({ loading: false });
            }
        } else {
            this.setState({ loading: false });
        }
    }

    signInFirebase = async (auth0Token) => {
        return await signInFirebaseWithAuth0Token(getFirebaseCustomToken, auth0Token)
    }

    render() {
        const idToken = localStorage.getItem("idToken");
        const secret_key = localStorage.getItem("secret_key");
        const { widget } = this.props.params;
        const WidgetComponent = widgetsComponent[widget] || UIFallbackScreen;

        if ((idToken || secret_key) && !this.state.loading) {
            return (
                <Switch>
                    <Route path={`/widget/v3/${widget}`} render={(props) => <WidgetComponent {...props} widgetVersion={3} params={this.props.params} widgetApp={widget} />}/>
                    <Route path={`/widget/v2/${widget}`} render={(props) => <WidgetComponent {...props} widgetVersion={2} params={this.props.params} widgetApp={widget} />}/>
                    <Route path={`/widget/${widget}`} render={(props) => <WidgetComponent {...props} widgetVersion={1} params={this.props.params} widgetApp={widget} />}/>
                </Switch>
                
            )
        } else if (this.state.loading) {
            return (<LifeSherpaLoading loading={this.state.loading} />);
        } else {
            return "Something went wrong, Unable to load lifesherpa widget";
        }

    }
}


export default WidgetController;
/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { Label } from 'reactstrap';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
//Actions
import {
    clearFormErrorResponse, 
    clearClientErrorResponse,
    getEastBayAllClientMembers,
    getClientFormData
} from '../../../actions'
import { ListItem } from '@mui/material';
import ActivityConfirmForm from '../../../components/ActivityForm/ActivityConfirmForm';
import AppConfig from '../../../constants/AppConfig';
import { Link } from 'react-router-dom';
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';
import SweetAlert from 'react-bootstrap-sweetalert';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

class Touchpoint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null,
            selected: '',
            coursePanel: false,
            assingnActivityPanel: false,
            search: "",
            openForm: false,
            selectedClient: null
        }
        this.onCancel = this.onCancel.bind(this)
    }

    componentDidMount() {
        console.group("%c[Touchpoint]%c Rendered", "color:black", "color:green");
        this.props.clearFormErrorResponse() 
        this.props.clearClientErrorResponse()
        const secret_key = localStorage.getItem('secret_key')
        if (secret_key) {
            this.props.getEastBayAllClientMembers(secret_key)
        }
    }

    handelSearch = (e) => {
        // console.log(e);
        this.setState({...this.state, search:e.target.value});
    }

    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }

    getGroupMembersList(search) {
        let groupMembersList = this.props.clientsData
		if (groupMembersList !== null) {
            groupMembersList.sort((details1, details2) => {
                return details1.name.localeCompare(details2.name);
            })
            if (search && search.length > 2 && groupMembersList.length > 0) {
                groupMembersList = groupMembersList.filter((member) => (member.name.toLowerCase().includes(search.toLowerCase())))
            } 
		}
        
        return groupMembersList
    }

    getClientOrg(user) {
        const {organizations} = this.props
        const organization = organizations ? organizations.find(org => org.id === user.organization) : null;
        return organization
    }

    handleSelectClient(selectedClient) {
        const secret_key = localStorage.getItem("secret_key")
        const {userId} = selectedClient
        this.props.getClientFormData(secret_key, userId)
        this.setState({selectedClient, openForm: true})
    }

    handleCloseForm() {
        this.setState({openForm: false})
    }

    onCancel() {
        this.props.clearFormErrorResponse() 
        this.props.clearClientErrorResponse()
    }

    render() {
        const {loading, selectedGroup, clientPokeFormLoading,  clientPokeForm, themeLoading, themeData, responseMessage, errorResponseMessage} = this.props
        const {search, openForm, selectedClient} = this.state
        const clientList = this.getGroupMembersList(search)
        const activeThemeId = localStorage.getItem('activeTheme');
        const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
		const localLogo = activeThemeId == 9 || activeThemeId == 8 || activeThemeId == 5 || activeThemeId == 10 ?  AppConfig.appLogoWhite : AppConfig.appLogo;
        const activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};

        return (
            <div className="w-100 " data-testid="touchpoint-widget">
                <div className="row">
                        <RctCollapsibleCard
                            colClasses="col-12 w-xs-full"
                            heading={
                                <div className="d-flex align-items-center justify-content-between">
                                    {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
                                        <Link to="/" className={`lifesherpa-widget-theme-logo text-center ${themeData && (themeData.logoBackgroundColor) ? "header-theme-logo-background" : ""}`}>
                                            {!themeLoading && 
                                            <img data-testid="ls-logo"  src={themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-widget-resize-img' />
                                            }
                                        </Link>
                                    : !themeLoading &&
                                    <Link to="/" className={`lifesherpa-widget-theme-logo text-center ${themeData && (themeData.logoBackgroundColor) ? "header-theme-logo-background" : ""}`} >
                                        <img data-testid="ls-logo"  src={ themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-widget-resize-img'/>
                                        <div className="d-flex  justify-content-center lifesherpa-poweredby-logo">
                                        {themeData && themeData.loginLifeSherpaImage &&
                                            <img src={themeData.loginLifeSherpaImage} alt="session-logo" width="auto" height="30" />
                                        }
                                        </div>
                                    </Link>
                                    }	
                                    <div className='ml-3'>East Bay Sensor Notification Response Page</div>	
                                    <div></div>			
                                </div>
                            }
                            fullBlock
                            customClasses="overflow-hidden theme-background "
                            headingCustomClasses="bg-lifesherpa"
                            titlecustomClassess="mb-0"
                        >
                            <div className="search-activity-field w-100">
                                <div className='p-3'>
                                    <p className='mb-0'>This page allows you to select a user and submit its notification response form to LifeSherpa. This will start the "Sensor Notification Process" workflow in LifeSherpa.</p>
                                </div>
                                <form className='list-card p-3' noValidate autoComplete="off">
                                    <TextField data-testid="my-client-search-text-field" id="standard-search" 
                                        onChange={(e) => this.handelSearch(e)}
                                        label="Search Client" 
                                        type="search" 
                                        value={search} 
                                        InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary"/>
                                        </InputAdornment>
                                        ),
                                        endAdornment: ( search && search.length > 0 ?
                                            <InputAdornment position="end">
                                            <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                            </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                        )
                                        }}
                                        variant="standard" 
                                    />
                                    <Label className='mb-0 mt-2' style={{fontWeight: 'bold'}}>{`Total Clients (${clientList && clientList.length > 0 ? clientList.length : 0})`}</Label>
                                </form>
                            </div>
                            <List className='p-3'>
                                { clientList && clientList.length > 0 ? clientList.map((user, key) => (
                                    <ListItem className={ selectedClient && user.userId === selectedClient.userId ? "item-active list-card cps" : "list-card cps"} data-testid={`my-clients-memebers-list${key}`} key={key} onClick={() => this.handleSelectClient(user)}>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                {this.getClientOrg(user) && <Label className='mb-0 d-flex align-items-center justify-content-center'><CorporateFareIcon className='mr-1'/>{this.getClientOrg(user).name}</Label>}
                                                <Label className='mb-0 ml-2 d-flex align-items-center justify-content-center'><PeopleOutlineIcon className='mr-1'/>{user && user.groupsName &&  user.groupsName.length  > 0 ? user.groupsName.join(", "): ""}</Label>
                                            </div>
                                            <div className='d-flex align-items-center avatar-icon'>
                                                {user.profileImageURL && user.profileImageURL != "" ? 
                                                    <img data-testid={`my-clients-client-image`} src={user.profileImageURL} className="img-fluid rounded-circle " alt="" width="60" height="60" />
                                                    :
                                                    <AccountCircleRoundedIcon fontSize='large'/>
                                                }
                                                <h4 style={{ fontWeight: "550", cursor: 'pointer' }} className="mb-0 ml-1 mb-1">
                                                    {user ? user.name : "Display Name"}
                                                </h4>
                                            </div>
                                        </div>
                                    </ListItem>
                                    ))
                                    : !loading && (!clientList || (clientList && clientList.length == 0)) ?
                                    <ListItem data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                        <h4>No Client Member Found</h4>
                                    </ListItem>
                                    : 
                                    <ListItem data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                        <h4>Fetching Client Members...</h4>
                                    </ListItem>
                                }
                            </List>
                        </RctCollapsibleCard>
                        <LifeSherpaLoading loading={loading}/>
                </div>
                <ActivityConfirmForm
                    open={openForm ? openForm : clientPokeFormLoading}
                    handleClose={this.handleCloseForm.bind(this)}
                    loading={clientPokeFormLoading}
                    pokeForm={clientPokeForm}
                    selectedGroup={selectedGroup}
                    isPokeForClient={true}
                    profileImage={selectedClient && selectedClient.profileImageURL}
                    displayName={selectedClient && selectedClient.name}
                    formId={clientPokeForm && clientPokeForm.formId}
                    orgId={selectedClient && selectedClient.organization}
                    selectedMemberId={selectedClient && selectedClient.userId}
                    widgetApp={true}
                    title={clientPokeForm && clientPokeForm.formName ? clientPokeForm.formName : "Fill Form"}
                    description={clientPokeForm && clientPokeForm.description ? clientPokeForm.description : "Form Description"}
                />
                {(errorResponseMessage || responseMessage) &&
                    <SweetAlert 
                        success={responseMessage ? true : false}
                        warning={errorResponseMessage ? true : false}
                        title={errorResponseMessage ? "Warning!" : "Good job!"} 
                        onConfirm={this.onCancel}
                        btnSize="sm"
                        customClass="successText"
                        confirmBtnText="OK"
                        confirmBtnBsStyle="success"
                        >
                        {errorResponseMessage ? errorResponseMessage : `${responseMessage} for ${selectedClient ? selectedClient.name : ""}`}
                    </SweetAlert>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ TouchpointReducer, clientsList, PokeForm, authUser, GroupList, settings}) => {
    const {clientsData, loading} = TouchpointReducer
    const {organizations} = clientsList
    const { clientPokeFormLoading,  clientPokeForm, responseMessage} = PokeForm
    const {pokeFormId, themeLoading, themeData} = authUser
    const { selectedGroup } = GroupList
    const { themeLogo } = settings; 
    const errorResponseMessage = TouchpointReducer.errorResponseMessage || PokeForm.errorResponseMessage
    return { clientsData, loading, organizations, clientPokeFormLoading,  clientPokeForm, pokeFormId, selectedGroup, themeLoading, themeLogo, themeData, responseMessage, errorResponseMessage }
}

export default connect(mapStateToProps, {
    getClientFormData,
    getEastBayAllClientMembers,
    clearFormErrorResponse, 
    clearClientErrorResponse
})(Touchpoint)
